<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<v-form v-model="isValid">
				<w-section sticky :title="$t('profile.user.civil_status') + ' :'">
					<v-layout row wrap>
						<v-flex shrink px-1>
							<v-autocomplete v-model="user.title" :label="$t('profile.user.civility')" :items="titles" prepend-icon="person"></v-autocomplete>
						</v-flex>
						<v-flex grow px-1>
							<w-text-input v-model="user.lastname" :label="$t('profile.user.lastname')" maxlength="191" required validate-on-blur></w-text-input>
						</v-flex>
						<v-flex grow px-1>
							<w-text-input v-model="user.firstname" :label="$t('profile.user.firstname')" maxlength="191" required validate-on-blur></w-text-input>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex grow px-1>
							<w-text-input v-model="user.birth_city" :label="$t('profile.user.birth_city')" maxlength="191" prepend-icon="place"></w-text-input>
						</v-flex>
						<v-flex shrink px-1>
							<w-date-picker v-model="user.birthday" :birthday="true" :label="$t('profile.user.birth_date')"></w-date-picker>
						</v-flex>
					</v-layout>
				</w-section>
				<w-section sticky :title="$t('profile.user.communication') + ' :'">
					<v-layout row wrap>
						<v-flex grow px-1>
							<w-email-input v-model="user.email" disabled :label="$t('profile.user.email')" prepend-icon="email" />
						</v-flex>
						<v-flex shrink px-1>
							<w-phone-input v-model="user.mobile_number" :label="$t('profile.user.mobile_number')" prepend-icon="phone" />
						</v-flex>
					</v-layout>
				</w-section>
				<w-section sticky :title="$t('profile.user.coordinates') + ' :'">
					<v-layout row>
						<v-flex grow px-1>
							<v-textarea v-model="user.address" auto-grow :label="$t('profile.user.address')" maxlength="191"></v-textarea>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex grow px-1>
							<w-text-input v-model="user.post_code" :label="$t('profile.user.post_code')" mask="#####" maxlength="5"></w-text-input>
						</v-flex>
						<v-flex grow px-1>
							<w-text-input v-model="user.city" :label="$t('profile.user.city')" maxlength="191"></w-text-input>
						</v-flex>
						<v-flex grow px-1>
							<w-text-input v-model="user.country" :label="$t('profile.user.country')" maxlength="191"></w-text-input>
						</v-flex>
					</v-layout>
				</w-section>
			</v-form>
			<v-layout align-center column py-3>
				<v-flex my-2 shrink>
					<w-btn :disabled="!isValid" :loading="loading" @click="updatePersonalInfo()">{{ $t('actions.save') }}</w-btn>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

import UserProfileModuleGuard from '@/mixins/ModulesGuards/UserProfile/UserProfileModuleGuard'
import Validator from '@/mixins/Validator'

export default {
	name: 'UserInfo',
	mixins: [UserProfileModuleGuard, Validator],
	data: function () {
		return {
			isValid: false,
			loading: false,
			panel: [],
			titles: [this.$t('customer.users.civility.mister'), this.$t('customer.users.civility.madam')]
		}
	},
	computed: {
		...mapState({
			user: state => state.user.data
		})
	},
	watch: {
		user: {
			deep: true,
			handler: function () {
				if (!this.user.lastname || !this.user.firstname) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('info.navigation_missing_info'))
				}
			},
			immediate: true
		}
	},
	methods: {
		updatePersonalInfo: function () {
			this.loading = true
			const data = {
				address: this.user.address,
				birthday: this.user.birthday,
				birth_city: this.user.birth_city,
				city: this.user.city,
				country: this.user.country,
				email: this.user.email,
				firstname: this.user.firstname,
				lastname: this.user.lastname,
				mobile_number: this.user.mobile_number,
				post_code: this.user.post_code,
				title: this.user.title
			}
			this.service
				.updateUserInfo(data)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('profile.messages.data_saved'))
				})
				.finally(() => {
					this.loading = false
				})
				.catch(err => {
					if (!err.flag) {
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, err)
					}
				})
		}
	}
}
</script>
